import { v4 as uuidv4 } from "uuid";

const SliderData = [
  {
    id: uuidv4(),
    title: "Lorem ipsum",
  },  
  {
    id: uuidv4(),
    title: "Lorem ipsum",
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
  },
];

export default SliderData;