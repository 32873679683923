import './App.css'
import React from 'react'

const Etusivu = () => {
    return (
        <div className='divEtusivu'>
            <h1 className='etuTitle'>MATKUSTA</h1>
            <h4 className='etuText'>vuokraamalla matkailuauto</h4>             
        </div>
    )
}

export default Etusivu
